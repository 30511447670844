<template>
<div class="full-page-loader">
  <div class="spinner-grow text-primary" role="status" style="width: 5rem; height: 5rem;">
    <span class="visually-hidden">Loading...</span>
  </div>
</div>
</template>

<script>
export default {
  name: "Loader"
}
</script>

<style scoped>
.full-page-loader {
  position: fixed;
  z-index: 500;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255,255,255,0.45);
}
</style>