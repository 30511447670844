
import {defineComponent, ref, onMounted, watch, PropType, computed} from "vue";
import {Toast} from "bootstrap";

export default defineComponent({
  name: 'Toast',
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>
    },
    type: {
      type: String as PropType<'info' | 'error'>,
      default: 'info'
    },
    message: {
      type: String as PropType<string>,
      required: true
    }
  },
  setup(props) {
    const toastRef = ref()
    const toast = ref<Toast | null>(null)
    const toastType = computed(() => {
      if (props.type === 'info') {
        return 'bg-primary'
      } else {
        return 'bg-error'
      }
    })

    watch(props, (act) => {
      if (act.modelValue) {
        if (toast.value) {
          toast.value.show()
        }
      } else {
        if (toast.value) {
          toast.value.hide()
        }
      }
    })


    onMounted(() => {
      if (toastRef.value) {
        toast.value = new Toast(toastRef.value)
      }
    })

    return {toastRef, toastType}
  }
})
