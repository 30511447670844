import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-16b208aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "headlineWrapper" }
const _hoisted_2 = { class: "headlineContainer" }
const _hoisted_3 = { class: "intro" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.header_text), 1),
      _createElementVNode("h1", null, _toDisplayString(_ctx.header_text_special), 1),
      _createElementVNode("h2", null, _toDisplayString(_ctx.header_text_name), 1)
    ])
  ]))
}