
import {defineComponent, onMounted, PropType, ref, watch} from "vue";
import {Offcanvas} from "bootstrap";


export default defineComponent({
  name: 'OffcanvasMenu',
  props: {
    modelValue: {
      type: Boolean as PropType<boolean>
    }
  },
  setup(props) {
    // eslint-disable-next-line no-undef
    const mainMenu = ref<Element | null>(null)
    const offCanvas = ref<Offcanvas | null>(null)

    watch(props, (act) => {
      if (act.modelValue) {
        if (offCanvas.value) {
          offCanvas.value.show()
        }
      } else {
        if (offCanvas.value) {
          offCanvas.value.hide()
        }
      }
    })

    const openMenu = ():void => {
      offCanvas.value && offCanvas.value.show()
    }

    onMounted(() => {
      if (mainMenu.value) {
        offCanvas.value = new Offcanvas(mainMenu.value)
      }
    })

    return {mainMenu, openMenu}
  }
})
