class InternalLocalStorageHelper {
    private readonly storagePrefix = 'hamprockLove-'

    getItem(key: string): string | null {
        return localStorage.getItem(`${this.storagePrefix}${key}`)
    }

    setItem(key: string, value: string): void {
        localStorage.setItem(`${this.storagePrefix}${key}`, value)
    }

    removeItem(key: string): void {
        localStorage.removeItem(`${this.storagePrefix}${key}`)
    }
}

export const LocalStorageHelper = new InternalLocalStorageHelper()
