
import {defineComponent, PropType} from "vue";
export default defineComponent({
  name: "DateContainer",
  props: {
    title: {
      type: String as PropType<string>
    },
    dateLine: {
      type: String as PropType<string>
    },
    yearLine: {
      type: String as PropType<string>
    }
  }
})
