
import {defineComponent, onMounted, ref, inject} from 'vue';
import {HomepageRemoteItem} from "@/Types/HomepageRemoteItem";
import {StrapiService} from "@/Services/StrapiService";
import Headline from "@/components/Headline.vue";
import DateContainer from "@/components/DateContainer.vue";
import LoginForm from "@/components/User/LoginForm.vue";
import Loader from "@/components/App/Loader.vue";

export default defineComponent({
  name: 'Home',
  components: {LoginForm, DateContainer, Headline, Loader},
  setup() {
    const homepageData = ref<HomepageRemoteItem>(new HomepageRemoteItem())
    const isLoading = ref<boolean>(true)
    const dayJs = inject('dayJs')

    const initHomepage = async (): Promise<void> => {
      isLoading.value = true
      StrapiService.getHomePage().then((response => {
        homepageData.value = response
      })).catch(reason => {
        console.log('Server Error: ', reason)
      }).finally(() => {
        isLoading.value = false
      })
    }

    const textConverter = (text: string): string => {
      if (text) {
        return text.replaceAll('\n', '<br/>')
      }
      return ''
    }

    onMounted(async () => {
      await initHomepage()
    })

    return {isLoading, homepageData, dayJs, textConverter}
  }
});
