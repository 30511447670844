import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue')
  },
  {
    path: '/wirsinddabei',
    name: 'WirSindDabei',
    component: () => import(/* webpackChunkName: "userRegistration" */ '../views/UserRegistration.vue')
  },
  {
    path: '/gaesteprofil',
    name: 'GastProfil',
    component: () => import(/* webpackChunkName: "guestProfile" */ '../views/GuestProfile.vue')
  },
  {
    path: '/upload',
    name: 'ImageUpload',
    component: () => import(/* webpackChunkName: "imgageUpload */ '../views/ImageUpload.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
