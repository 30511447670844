import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import dayjs from "dayjs";
import 'dayjs/locale/de';
import '@/styles/mainStyles.scss';
import 'bootstrap-icons/font/bootstrap-icons.scss';
dayjs().locale('de')

createApp(App).use(router).provide('dayJs', dayjs).mount('#app')
