
import {defineComponent, ref} from "vue";
import OffcanvasMenu from "@/components/App/OffcanvasMenu.vue";
export default defineComponent({
  name: 'App',
  components: {OffcanvasMenu},
  setup() {
    const menu = ref<boolean>(false)

    const showMenu = () => {
      menu.value = !menu.value
    }

    return {menu, showMenu}
  }
})
