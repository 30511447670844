
import {defineComponent, PropType} from "vue";
export default defineComponent({
  name: "Headline",
  props: {
    header_text: {
      type: String as PropType<string>,
      required: true
    },
    header_text_name: {
      type: String as PropType<string>,
      required: true
    },
    header_text_special: {
      type: String as PropType<string>,
      required: true
    }
  }
})
