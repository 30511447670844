
import {defineComponent, ref, onMounted} from "vue";
import {LoginData} from "@/Types/User/LoginData";
import {UserService} from "@/Services/UserService";
import Toast from "@/components/App/Toast.vue";
import {LocalStorageHelper} from "@/Services/LocalStorageHelper";
import {useRouter} from "vue-router";

export default defineComponent({
  name: 'LoginForm',
  components: {Toast},
  setup() {
    const login = ref<LoginData>(new LoginData())
    const showToast = ref<boolean>(false)
    const toastType = ref<'info'|'error'>('info')
    const toastMessage = ref<string>('')
    const loggedIn = ref<boolean>(false)
    const router = useRouter()

    const loginUser = async () => {
      if (login.value.username && login.value.password) {
        await UserService.loginUser(login.value)
        const token = LocalStorageHelper.getItem('token')
        if (token) {
          loggedIn.value = true
          await router.push('/gaesteprofil')
        }
      } else {
        toastMessage.value = 'Bitte Benutzername und Passwort eingeben!'
        showToast.value = true
      }
    }

    const logoutUser = () => {
      UserService.logoutUser()
      loggedIn.value = false
    }

    onMounted(() => {
      const token = LocalStorageHelper.getItem('token')
      if (token) {
        loggedIn.value = true
      }
    })

    return {login, showToast, toastType, toastMessage, loggedIn, loginUser, logoutUser}
  }
})
