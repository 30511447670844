import axios from "axios";
import {HomepageRemoteItem} from "@/Types/HomepageRemoteItem";

class InternalStrapiService {
    private _apiHost = 'https://wedding.backend-hamperl.de/api/'

    async getHomePage(): Promise<HomepageRemoteItem> {
        const homepageResponse = await axios.get(this._apiHost + 'homepage')

        const returnData: HomepageRemoteItem = homepageResponse.data.data.attributes

        return returnData
    }

    async uploadImage(item: {title: string, file: File}) {
        const formData = new FormData()
        formData.append('files', item.file)

        await axios.post(this._apiHost + 'upload',
            formData, {headers: {'Content-Type': 'multippart/form-data'}})
            .then(async (response) => {
            console.log('UPLOAD RESPONSE ', response)
            await axios.post(this._apiHost + 'slide-shows', {
                data: {
                    Title: item.title,
                    Image: response.data[0]
                }})
                .then(createResponse => {
                    console.log('created!', createResponse)
                })
            })
        .catch(error => {
            console.log('ERROR', error)
        })
    }
}

export const StrapiService = new InternalStrapiService()