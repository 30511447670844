import {NewUser} from "@/Types/User/NewUser";
import axios from "axios";
import {RemoteUserCreated} from "@/Types/User/RemoteUserCreated";
import {LocalStorageHelper} from "@/Services/LocalStorageHelper";
import {LoginData} from "@/Types/User/LoginData";

class InternalUserService {
    private _apiHost = 'https://wedding.backend-hamperl.de/api/'

    async createUser(newUser: NewUser): Promise<boolean> {
        const newUserResponse = await axios.post<RemoteUserCreated>(`${this._apiHost}auth/local/register`, {
            password: newUser.password,
            email: newUser.email,
            username: newUser.username
        })

        if (newUserResponse.status >= 300) {
            return false
        } else {
            LocalStorageHelper.setItem('token', newUserResponse.data.jwt)
            LocalStorageHelper.setItem('userData', JSON.stringify(newUserResponse.data.user))
           return true
        }
    }

    async loginUser(credentials: LoginData): Promise<void> {
        const loginRes = await axios.post(`${this._apiHost}auth/local`, {
            identifier: credentials.username,
            password: credentials.password
        })

        const {jwt, user} = loginRes.data
        LocalStorageHelper.setItem('token', jwt)
        LocalStorageHelper.setItem('userData', JSON.stringify(user))
    }

    logoutUser = ():void => {
        LocalStorageHelper.removeItem('token')
        LocalStorageHelper.removeItem('userData')
    }
}

export const UserService = new InternalUserService();